import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Success = () => {
  const { state } = useLocation();
  console.log(state);
  const navigate = useNavigate();
  useEffect(() => {
    if (!state) {
      navigate("/", { replace: true });
    }
  }, [state, navigate]);
  return (
    <main className="flex min-h-[100dvh] flex-col items-center justify-center gap-6">
      <img src="/sucesss.gif" alt="success" className="w-40" />
      <p className="text-xl font-semibold uppercase text-white">
        {state?.message || "Success"}
      </p>
      <button
        onClick={() => navigate("/")}
        className="rounded-md bg-primary-100 px-4 py-2 font-semibold text-black "
      >
        Enjoy Watching
      </button>
    </main>
  );
};

export default Success;
