import { Route, Routes } from "react-router-dom";
import Layout from "./components/commen/Layout/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";

import "swiper/css";
import AllSubscrition from "./pages/AllSubscrition";

import MovieDetails from "./pages/MovieDetails";
import SearchMobile from "./pages/Search";
import SeriesDetails from "./pages/SeriesDetails";
import ThirdPart from "./pages/ThirdParty";
import ThirdPartyHome from "./pages/ThirdPartyHome";
import WatchList from "./pages/WatchList";
import Account from "./pages/profile/Account";
import Rented from "./pages/profile/Rented";
import About from "./pages/static/About";
import Privacy from "./pages/static/Privacy";
import RefundPolicy from "./pages/static/Refund";
import Terms from "./pages/static/Terms";
import Contact from "./pages/static/Contact";
import ViewAll from "./pages/ViewAll";
import Success from "./pages/static/Success";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={
            <Layout>
              <Login />
            </Layout>
          }
        />
        <Route
          path="/view-all/:id"
          element={
            <Layout>
              <ViewAll />
            </Layout>
          }
        />
        <Route
          path="/third-party"
          element={
            <Layout thirdParty>
              <ThirdPartyHome />
            </Layout>
          }
        />
        <Route
          path="/third-party/:id"
          element={
            <Layout thirdParty>
              <ThirdPart />
            </Layout>
          }
        />

        <Route
          path="/watchlist"
          element={
            <Layout>
              <WatchList />
            </Layout>
          }
        />
        <Route
          path="/"
          element={
            <Layout active>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/success"
          element={
            <Layout>
              <Success />
            </Layout>
          }
        />
        <Route
          path="/search"
          element={
            <Layout active>
              <SearchMobile />
            </Layout>
          }
        />
        <Route
          path="/movie/:id"
          element={
            <Layout>
              <MovieDetails />
            </Layout>
          }
        />
        <Route
          path="/series/:id"
          element={
            <Layout>
              <SeriesDetails />
            </Layout>
          }
        />
        <Route
          path="/subscriptions"
          element={
            <Layout>
              <AllSubscrition />
            </Layout>
          }
        />
        <Route
          path="/my-account"
          element={
            <Layout>
              <Account />
            </Layout>
          }
        />
        <Route
          path="/my-account/rented"
          element={
            <Layout>
              <Rented />
            </Layout>
          }
        />
        <Route
          path="/termsandconditions"
          element={
            <Layout>
              <Terms />
            </Layout>
          }
        />
        <Route
          path="/privacyPolicy"
          element={
            <Layout>
              <Privacy />
            </Layout>
          }
        />
        <Route
          path="/about-us"
          element={
            <Layout>
              <About />
            </Layout>
          }
        />
        <Route
          path="/refund"
          element={
            <Layout>
              <RefundPolicy />
            </Layout>
          }
        />
        <Route
          path="/contact-us"
          element={
            <Layout>
              <Contact />
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
