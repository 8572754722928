import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../components/commen/Modal";
import {
  buySubscription,
  checkAccess,
  generateRandomString,
  getAllSubscriptions,
  getOtherSub,
  getOtherSubs,
  getProfile,
  getSubStatus,
  phonePay,
  updateSubscription,
  url,
} from "../utils/apis";
import useStore from "../utils/store";
import { BsFillPatchCheckFill } from "react-icons/bs";
import Loading from "../components/commen/Loading";
import {
  PayPalScriptProvider,
  PayPalButtons,
  BraintreePayPalButtons,
} from "@paypal/react-paypal-js";
import { RiLoader5Line } from "react-icons/ri";
const AllSubscrition = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const user = useStore((state) => state.user);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [success, setSuccess] = React.useState(false);
  const [profile, setProfile] = React.useState(null);
  const getOwnSubscriptions = async () => {
    setLoading(true);
    const [success, response] = !profile?.subscriptionValid
      ? await getAllSubscriptions()
      : await getOtherSubs(user.token);
    if (success) {
      setSubscriptions(response);
    }
    setLoading(false);
  };

  console.log(subscriptions);

  const checkAccessHandler = async () => {
    const [success, response] = await getSubStatus(user?.token);
    if (success) {
      setProfile(response);
      console.log(response);
    }
  };

  const buySubscriptionHandler = async (id) => {
    if (!user) {
      navigate("/login");
    }
    setLoading(true);
    const [success, response] = !profile?.subscriptionValid
      ? await buySubscription(user.token, {
          subscriptionId: id,
        })
      : await updateSubscription(user.token, {
          subscriptionId: id,
        });
    console.log(success, response);
    if (success) {
      getOwnSubscriptions();
      setSuccess(true);
    }
    setLoading(false);
  };
  React.useEffect(() => {
    getOwnSubscriptions();
  }, [profile]);

  React.useEffect(() => {
    checkAccessHandler();
  }, []);

  if (loading) return <Loading />;
  return (
    <PayPalScriptProvider
      options={{
        clientId:
          "ATLZrWIA1S-MMQfYKaiOT8sEvzg6v3PStWx8rvVTgB6UfrJ0xnd8pUzfiiUqadrNzMSChodvVeX6upO7",
        intent: "capture",
        currency: "USD",
      }}
    >
      <div className="flex flex-1 justify-center py-10">
        <div className="grid w-[80%] gap-10  md:grid-cols-2 lg:grid-cols-2">
          {subscriptions?.map((item) => (
            <Card
              data={item}
              onClick={() => buySubscriptionHandler(item._id)}
              key={item._id}
            />
          ))}
        </div>
        {success && (
          <Modal>
            <div className="flex flex-col items-center justify-center gap-4 rounded bg-white py-10">
              <BsFillPatchCheckFill className="text-7xl text-green-600" />
              <div className="flex flex-col items-center gap-1">
                <h1 className="text-xl font-semibold">
                  You have successfully subscribed
                </h1>
                <p className="text-sm  text-gray-600">
                  Thanks for subscribing to our service
                </p>
              </div>
              <button
                onClick={() => navigate("/my-account")}
                className="rounded-md bg-primary-100 px-4 py-2 font-semibold text-white"
              >
                Enjoy Watching
              </button>
            </div>
          </Modal>
        )}
      </div>
    </PayPalScriptProvider>
  );
};

export default AllSubscrition;

export const Card = ({ data, onClick }) => {
  const [loading, setLoading] = React.useState(false);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const payHandler = async () => {
    if (!user?.token) {
      return navigate("/login");
    }

    setLoading(true);

    const [success, response] = await buySubscription(user.token, {
      subscriptionId: data._id,
    });

    if (success) {
      navigate("/success", {
        state: { message: "You have successfully subscribed" },
      });
    }

    // const [success, response] = await phonePay(
    //   {
    //     amount: data?.price * 100,
    //     callbackUrl: `${url}/callback?purchaseType=subscription&id=${data?._id}&userId=${user?.user?._id}`,
    //     deviceContext: {
    //       deviceOS: "ANDROID",
    //     },
    //     merchantId: "M22NADQ7D85DZ",
    //     redirectUrl: `https://ayyotv.com/`,
    //     redirectMode: "GET",
    //     merchantTransactionId: generateRandomString(10).toUpperCase(),
    //     merchantUserId: user?.user?._id,
    //     mobileNumber: "9876543210",
    //     paymentInstrument: {
    //       type: "PAY_PAGE",
    //     },
    //   },
    //   user.token
    // );

    // if (success) {
    //   window.location.replace(
    //     response?.data?.data?.instrumentResponse?.redirectInfo?.url
    //   );
    // }
    setLoading(false);
  };

  const price = data?.price;
  return (
    <div className=" relative h-fit rounded-md bg-white">
      {loading && (
        <div className="absolute top-0 left-0  flex h-full w-full items-center justify-center bg-black bg-opacity-50">
          <RiLoader5Line className="h-10 w-10 animate-spin text-white" />
        </div>
      )}
      <div className=" rounded-t-md bg-gradient-to-r from-primary-100 via-primary-100 to-primary2 px-10 py-5 text-center text-sm font-semibold uppercase text-white  ">
        {data?.title}
      </div>
      <div className="flex flex-col gap-3 px-4 pb-2 pt-4 ">
        <CardItem title="Price" value={data?.price} />
        <CardItem title="Validity" value={data?.validity + " " + "days"} />
      </div>
      <div className="flex flex-row items-center justify-center px-4 pb-4  pt-2">
        {user?.token ? (
          !loading && (
            <PayPalButtons
              className="w-full"
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: price,
                        currency_code: "USD",
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then((details) => {
                  payHandler();
                });
              }}
            />
          )
        ) : (
          <Link to="/login">
            <button className="rounded-md bg-primary-100 px-4 py-2 font-semibold text-white hover:bg-opacity-80">
              Subscribe
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

const CardItem = ({ title, value }) => {
  return (
    <div className="flex flex-row items-center justify-between gap-3">
      <p className="text-sm font-[600]">{title}</p>
      <p className="font-[600]  text-primary-100"> {value} </p>
    </div>
  );
};
